import { useCallback, useEffect } from 'react';

import {
  type Repository,
  useArrayState,
} from '../../../src/hooks/useArrayState';
import {
  type TransformedAsyncCallState,
  useAsyncCall,
} from '../../../src/hooks/useAsyncCall';
import {
  type Paginable,
  type Paginator,
} from '../../../src/services/api-service';
import { type GameLike } from '../../../src/types/game';

export function useGameLikeListLoader<P extends Paginable, T extends GameLike>(
  paginator: Paginator<P, T>
): {
  items: T[];
  dao: Repository<T>;
  state: TransformedAsyncCallState;
  error: Error | null;
  handleRetry: () => void;
  handleLoadMore: () => void;
} {
  const [items, setItems, dao] = useArrayState<T>({
    prepend: true,
    compare: (a, b) => a.id === b.id,
  });

  const {
    state,
    error,
    call: load,
    reset,
  } = useAsyncCall(
    useCallback(async () => {
      const id = paginator.getId();
      const next = await paginator.next();
      // data source changed, ignore the response
      const latestId = paginator.getId();
      if (id !== latestId) {
        return;
      }
      setItems((prev) => {
        return [...prev, ...next];
      });
    }, [paginator, setItems])
  );

  const resetAll = useCallback(() => {
    setItems([]);
    paginator.reset();
    reset();
  }, [setItems, paginator, reset]);

  useEffect(() => {
    load();
    return () => {
      resetAll();
    };
  }, [load, resetAll]);

  const handleLoadMore = () => {
    if (!state.transformed.isRunning && paginator.hasMore()) load();
  };

  return {
    items,
    dao,
    state: state.transformed,
    error,
    handleLoadMore,
    handleRetry: load,
  };
}
